import React from 'react';
import { H2 } from '../design/SharedComponents';
import styled from 'styled-components';

const SectionHeadingSC = styled.div`
  background-color: ${(props) => props.theme.color.blue};
  min-height: 140px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:before {
    content: '';
    display: block;
    height: 60px; /* fixed header height*/
    margin: -60px 0 0; /* negative fixed header height */
  }

  @media only screen and (max-width: 800px) {
    min-height: 0;
  }
`;

interface Props {
  title: string;
  id?: string;
}

const SectionHeading: React.FC<Props> = ({ title, id }) => {
  return (
    <SectionHeadingSC id={id}>
      <H2>{title}</H2>
    </SectionHeadingSC>
  );
};

export default SectionHeading;
