import React from 'react';
import styled from 'styled-components';
import { About as IAbout } from '../content';
import { Text } from '../design/SharedComponents';

const AboutSC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextSC = styled(Text)`
  margin: 1em;
  max-width: 90ch;
  text-align: justify;
`;

export interface IParagraph {
  paragraph: string;
}

interface Props {
  paragraphs: IParagraph[];
}

const About: React.FC<Props> = ({ paragraphs }) => {
  return (
    <AboutSC>
      {paragraphs.map((p, i) => (
        <TextSC key={i}>{p.paragraph}</TextSC>
      ))}
    </AboutSC>
  );
};

export default About;
