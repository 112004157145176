import React from 'react';
import { ButtonSecondary, H3 } from '../design/SharedComponents';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { slugify } from '../helpers/slugify';
import { Markdown } from './Markdown';

interface ImageFile {
  file: ImageDataLike;
}

export interface IArticle {
  typ: 'produkty' | 'galeria' | 'sluzby';
  buttonText: string;
  title: string;
  description: string;
  image: ImageFile;
}

const ArticleSC = styled.div`
  min-height: max-content;
  display: flex;
  flex-direction: row;

  width: 100%;

  overflow: hidden;

  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;

    /* target direct child */
    > * {
      min-width: 100%;
    }
  }
`;

const ContainerSC = styled.div`
  width: 50vw;
  max-width: 50vw;
  overflow: hidden;

  display: flex;
  justify-content: center;
`;

const ImageContainerSC = styled.div`
  width: 50vw;
  max-width: 50vw;
  overflow: hidden;
`;

const DescriptionSC = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-self: center;

  max-width: 90ch;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  padding: 2em;
`;

const ButtonSecondarySC = styled(ButtonSecondary)`
  padding: 5px 3em;
`;

const Title = styled(H3)`
  text-align: left;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5em;
`;

const LinkSC = styled(Link)`
  align-self: center;
  margin-top: 2em;
`;

interface Props {
  article: IArticle;
}

const Article: React.FC<Props> = ({ article }) => {
  const image = getImage(article.image.file);

  return (
    <ArticleSC>
      <ContainerSC>
        <DescriptionSC>
          <Title>{article.title}</Title>
          <Markdown>{article.description}</Markdown>
          <LinkSC
            to={
              article.typ === 'produkty'
                ? 'produkty'
                : article.typ === 'sluzby'
                ? 'sluzby'
                : `galeria/${slugify(article.title)}`
            }
          >
            <ButtonSecondarySC>{article.buttonText}</ButtonSecondarySC>
          </LinkSC>
        </DescriptionSC>
      </ContainerSC>

      <ImageContainerSC>
        <GatsbyImage
          image={image}
          alt={article.title}
          style={{ width: 'auto', height: '100%' }}
          objectFit="cover"
          loading="lazy"
        />
      </ImageContainerSC>
    </ArticleSC>
  );
};

export default Article;
