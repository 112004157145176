import React from 'react';
import { ButtonBase, H1 } from '../design/SharedComponents';
import styled from 'styled-components';
import { Title as ITitle } from '../content';
import { StaticImage } from 'gatsby-plugin-image';

// @ts-ignore
import ArrowDown from '../assets/arrow-down.svg';

const TitleSC = styled.div`
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  max-height: 70vh;
  overflow: hidden;
`;

const HeroTextSC = styled(H1)`
  text-align: center;
  color: white;

  margin-bottom: 10px;
`;

const ButtonSC = styled(ButtonBase)`
  border: 2px solid ${({ theme }) => theme.color.white};

  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.grey};

  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);

  width: 200px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  text-decoration: none;

  @media only screen and (max-width: 600px) {
    visibility: hidden;
  }
`;

const HeroSC = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconSC = styled.div`
  max-height: 22px;
  max-width: 22px;
  min-height: 22px;
  min-width: 22px;
  display: flex;
  color: ${({ theme }) => theme.color.black};

  svg {
    width: 22px;
    height: 22px;
  }
`;

interface Props {
  title: ITitle;
}

const Title: React.FC<Props> = ({ title }) => {
  return (
    <TitleSC id="home">
      <StaticImage src="../images/hero.jpg" alt="Unikovo heading" />
      <HeroSC>
        <HeroTextSC>{title.title}</HeroTextSC>
        <ButtonSC as="a" href="/#articles">
          Zobraziť viac
          <IconSC>
            <ArrowDown />
          </IconSC>
        </ButtonSC>
      </HeroSC>
    </TitleSC>
  );
};

export default Title;
