import React from 'react';
import styled from 'styled-components';
import Article, { IArticle } from './Article';

const ArticlesListSC = styled.div`
  display: flex;
  flex-direction: column;

  > * :nth-child(2n) {
    flex-direction: row-reverse;

    @media only screen and (max-width: 800px) {
      flex-direction: column-reverse;
    }
  }
`;

interface Props {
  articles: IArticle[];
}

const ArticleList: React.FC<Props> = ({ articles }) => {
  return (
    <ArticlesListSC role="main">
      {articles.map((article) => (
        <Article key={article.title} article={article} />
      ))}
    </ArticlesListSC>
  );
};

export default ArticleList;
