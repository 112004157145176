import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const WIDTH = 96;

export const CERT_001 = (
  <StaticImage
    src="../testament/iso-9001.png"
    alt="Certifikát ISO 9001"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CERT_002 = (
  <StaticImage
    src="../testament/ecovadis.png"
    alt="Certifikát EcoVadis 2022"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);

export const CAR_001 = (
  <StaticImage
    src="../testament/citroen.png"
    alt="Citroen logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_002 = (
  <StaticImage
    src="../testament/kia.jpg"
    alt="Kia logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_003 = (
  <StaticImage
    src="../testament/peugeot.jpg"
    alt="Peugeot logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_004 = (
  <StaticImage
    src="../testament/porsche.jpg"
    alt="Porsche logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_005 = (
  <StaticImage
    src="../testament/seat.png"
    alt="Seat logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_006 = (
  <StaticImage
    src="../testament/skoda.jpg"
    alt="Škoda logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_007 = (
  <StaticImage
    src="../testament/volkswagen.jpg"
    alt="Volkswagen logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_008 = (
  <StaticImage
    src="../testament/opel.png"
    alt="Opel logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const CAR_009 = (
  <StaticImage
    src="../testament/land-rover.png"
    alt="Land Rover logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);

export const OTHER_001 = (
  <StaticImage
    src="../testament/vkp.png"
    alt="Volejbalový klub polície Bratislava logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const OTHER_002 = (
  <StaticImage
    src="../testament/plastic-omnium.png"
    alt="Plastic Omnium logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const OTHER_003 = (
  <StaticImage
    src="../testament/mobis.svg"
    alt="Mobis logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const OTHER_004 = (
  <StaticImage
    src="../testament/plakor.webp"
    alt="Plakor logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const OTHER_005 = (
  <StaticImage
    src="../testament/maier.png"
    alt="Maier CZ logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
export const OTHER_006 = (
  <StaticImage
    src="../testament/magna.jpg"
    alt="Magna Slovteca logo"
    objectFit="contain"
    loading="lazy"
    placeholder="none"
    width={WIDTH}
    style={{ width: '100%' }}
  />
);
