import React from 'react';
import styled from 'styled-components';
import { Contact as IContact } from '../content';
import { H3, Mail, Phone, Text } from '../design/SharedComponents';

const ContactSC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InfoContainerSC = styled.address`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-bottom: 2em;

  @media only screen and (min-width: 601px) {
    width: 60%;
    max-width: 700px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ColumnSC = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  & > * {
    margin: 2px 0;
  }
`;

const GoogleMap = styled.iframe`
  border: 0;
  width: 100%;
  height: 50vh;
`;

interface Props {
  contact: IContact;
}

const Contact: React.FC<Props> = ({ contact }) => {
  return (
    <ContactSC>
      <H3>{contact.heading}</H3>
      <InfoContainerSC>
        <ColumnSC>
          <Phone value={contact.phone} />
          <Mail value={contact.email} />
        </ColumnSC>

        <ColumnSC />

        <ColumnSC>
          <Text>{contact.addressLine1}</Text>
          <Text>{contact.addressLine2}</Text>
        </ColumnSC>
      </InfoContainerSC>

      <GoogleMap
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.176051680834!2d17.123898151444884!3d48.183959355806124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c8e995a7f454d%3A0x3f860dbd3d29d7d2!2sUnikovo%20s.r.o.!5e0!3m2!1ssk!2ssk!4v1632295948614!5m2!1ssk!2ssk"
        loading="lazy"
      />
    </ContactSC>
  );
};

export default Contact;
