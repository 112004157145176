import React from 'react';
import styled from 'styled-components';
import { H3 } from '../design/SharedComponents';
import {
  CERT_001,
  CAR_001,
  CAR_002,
  CAR_003,
  CAR_004,
  CAR_005,
  CAR_006,
  CAR_007,
  CAR_008,
  CAR_009,
  OTHER_001,
  OTHER_002,
  CERT_002,
  OTHER_003,
  OTHER_004,
  OTHER_005,
  OTHER_006,
} from '../images/shared/logos';

const TestamentSC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const CertificationSC = styled(Container)``;
const CarManufacturersSC = styled(Container)``;
const MoreSC = styled(Container)``;

const ImageContainer = styled.div`
  margin: 0 0.5em 0 0.5em;
  max-width: 6em;
  max-height: 6em;
  width: 6em;
  height: 6em;
  min-width: 6em;
  min-height: 6em;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const H3SC = styled(H3)`
  text-align: center;
`;

const Testament = () => {
  const certImages = [CERT_001, CERT_002];
  const carImages = [
    CAR_001,
    CAR_002,
    CAR_003,
    CAR_004,
    CAR_005,
    CAR_006,
    CAR_007,
    CAR_008,
    CAR_009,
  ];
  const moreImages = [
    OTHER_002,
    OTHER_003,
    OTHER_004,
    OTHER_005,
    OTHER_006,
    OTHER_001,
  ];

  return (
    <TestamentSC>
      <H3SC>Certifikáty</H3SC>
      <CertificationSC>
        {certImages.map((image, i) => (
          <ImageContainer key={i}>{image}</ImageContainer>
        ))}
      </CertificationSC>
      <H3SC>Pri výrobe používajú naše výrobky</H3SC>
      <CarManufacturersSC>
        {carImages.map((image, i) => (
          <ImageContainer key={i}>{image}</ImageContainer>
        ))}
      </CarManufacturersSC>
      <H3SC>Naši zákazníci</H3SC>
      <MoreSC>
        {moreImages.map((image, i) => (
          <ImageContainer key={i}>{image}</ImageContainer>
        ))}
      </MoreSC>
    </TestamentSC>
  );
};

export default Testament;
