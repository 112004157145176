import React from 'react';

import { seed } from '../content/seed';
import SectionHeading from '../components/SectionHeading';
import Title from '../components/Title';
import Contact from '../components/Contact';
import ArticleList from '../components/ArticleList';
import About, { IParagraph } from '../components/About';
import Testament from '../components/Testament';
import MetaTags from '../components/MetaTags';
import SiteTemplate from '../components/pagesTemplate/SiteTemplate';
import { graphql } from 'gatsby';
import { IArticle } from '../components/Article';

export const query = graphql`
  query Homepage {
    strapiHomepage {
      About {
        paragraph
      }
      Articles {
        id
        buttonText
        title
        description
        typ
        image {
          file {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                aspectRatio: 1.33
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    strapiHomepage: {
      Articles: Array<IArticle>;
      About: Array<IParagraph>;
    };
  };
}

const IndexPage = ({ data }: Props) => {
  const articles = data.strapiHomepage.Articles;
  const paragraphs = data.strapiHomepage.About;

  return (
    <SiteTemplate isMainPage>
      <MetaTags />

      <Title title={seed.title} />

      <SectionHeading id="articles" title={seed.sectionHeadings.articles} />

      <ArticleList articles={articles} />

      <SectionHeading id="about" title={seed.sectionHeadings.about} />
      <About paragraphs={paragraphs} />

      <SectionHeading id="quality" title={seed.sectionHeadings.testament} />
      <Testament />

      <SectionHeading id="contact" title={seed.sectionHeadings.contact} />

      <Contact contact={seed.contact} />
    </SiteTemplate>
  );
};

export default IndexPage;
