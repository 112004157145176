const charMap = {
  á: 'a',
  ä: 'a',
  č: 'c',
  ď: 'd',
  é: 'e',
  í: 'i',
  ĺ: 'l',
  ľ: 'l',
  ň: 'n',
  ó: 'o',
  ô: 'o',
  ŕ: 'r',
  š: 's',
  ť: 't',
  ú: 'u',
  ý: 'y',
  ž: 'z',
};

const rx = /(á|ä|č|ď|é|í|ĺ|ľ|ň|ó|ô|ŕ|š|ť|ú|ý|ž)/g;
const invalidChars = /[^a-z\d (á|ä|č|ď|é|í|ĺ|ľ|ň|ó|ô|ŕ|š|ť|ú|ý|ž)]/gi;

const removeInvalidChars = (text: string) =>
  text.trim().replace(invalidChars, '').replace(/( )+/g, ' ');

export const slugify = (title: string) => {
  let word = removeInvalidChars(title).toLowerCase();

  if (rx.test(word)) {
    word = word.replace(rx, function (m, key) {
      return charMap[key];
    });
  }

  return word.replace(/ /g, '-');
};
